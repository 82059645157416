<template>
  <div class="donateSubmit">
    <div class="title">
      <div class="title-box">我要捐款</div>
    </div>
    <div class="form">
      <div class="item">
        <div class="item-l">捐款人姓名:</div>
        <div class="item-r">
          <v-input v-model="form.name" placeholder="请输入姓名"></v-input>
        </div>
      </div>
      <div class="item">
        <div class="item-l">联系电话:</div>
        <div class="item-r">
          <v-input
            v-model="form.mobile"
            type="number"
            maxlength="11"
            placeholder="请输入电话"
          ></v-input>
        </div>
      </div>
      <div class="item te">
        <div class="item-l">捐款金额:</div>
        <div class="item-r">
          <v-input
            v-model="form.money"
            type="number"
            placeholder="请输入金额"
          ></v-input
          ><span>元</span>
        </div>
      </div>
      <div class="item textItem">
        <div class="item-l">捐款说明:</div>
        <div class="item-r">
          <v-input v-model="form.discribe" type="textarea"></v-input>
        </div>
      </div>
      <div class="item textItem imgItem">
        <div class="item-l">上传图片:</div>
        <div class="item-r">
          <v-upload
            :imgUrl.sync="form.pictures"
            :activityPicture.sync="form.pictures"
            ref="load1"
            :maxCount="9"
          ></v-upload>
        </div>
      </div>
      <div class="item textItem" v-if="form.id && form.status == 2">
        <div class="item-l">不通过原因:</div>
        <div class="item-r">
          <v-input
            v-model="form.auditRemarks"
            disabled
            type="textarea"
          ></v-input>
        </div>
      </div>
    </div>
    <template v-if="form.status == 0 || form.status == 2">
      <div class="btn" @click="submit(0)" v-if="!form.id">提交</div>
      <div class="save" v-if="form.id && form.status != 2" @click="submit(0)">
        保存
      </div>
      <div class="del" v-if="form.id && form.status != 2" @click="toDelete">
        删除
      </div>
      <div class="modify" v-if="form.id && form.status == 2" @click="submit(0)">
        审核不通过，修改重新提交
      </div>
    </template>
  </div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import {
  saveDonationUrl,
  deleteDonationUrl,
  getDonationByIdUrl,
} from "./api.js";
var moment = require("moment");
export default {
  name: "donateSubmit",
  data() {
    let _this = this;
    return {
      form: {
        id: "",
        donationType: 1,
        donationUserId: _this.$store.state.userId,
        tenantId: _this.$store.state.tenantId,
        name: "",
        mobile: "",
        money: "",
        discribe: "",
        pictures: "",
        status: "",
        auditRemarks: "",
      },
      isBstop: 0,
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id;
      this.getDetail();
    }
  },
  mounted() {},
  methods: {
    async toDelete() {
      let res = await this.$axios.post(
        `${deleteDonationUrl}?ids=${this.form.id}`
      );
      if (res.code === 200) {
        this.$toast({ message: "删除成功", duration: 2000 });
        this.$router.go(-1);
      }
    },
    async getDetail() {
      let params = {
        id: this.form.id,
      };
      let res = await this.$axios.get(
        `${getDonationByIdUrl}`,
        { params },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (res.code === 200) {
        Object.keys(this.form).forEach((key) => {
          this.form[key] = res.data[key];
        });
      }
    },
    async submit(data) {
      if (!this.form.name) {
        this.$toast({ message: "请填写姓名", duration: 2000 });
        return;
      }
      if (!this.form.mobile) {
        this.$toast({ message: "请填写电话", duration: 2000 });
        return;
      }
      if (!this.form.money) {
        this.$toast({ message: "请填写金额", duration: 2000 });
        return;
      }
      if (!this.form.discribe) {
        this.$toast({ message: "请填写捐款说明", duration: 2000 });
        return;
      }
      this.form.status = data;
      let res = await this.$axios.post(`${saveDonationUrl}`, this.form, {
        headers: { "Content-Type": "application/json" },
      });

      if (res.code === 200) {
        this.$toast({ message: "提交申请成功", duration: 2000 });
        this.$router.push({
          name: "donateRes",
          query: {
            isDonate: 1,
          },
        });
      } else {
      }
    },
  },
};
</script>
<style lang="less" scoped>
.donateSubmit {
  min-height: 100vh;
  padding: 22px 0 100px;
  box-sizing: border-box;
  .title {
    padding: 8px 30px;
    .title-box {
      font-weight: 700;
      font-size: 36px;
      color: #1a1c34;
      line-height: 50px;
      position: relative;
      padding-left: 20px;
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: 182px;
        height: 14px;
        background: #e2e2e2;
        border-radius: 7px;
        z-index: -1;
      }
    }
  }
  .form {
    padding: 0 42px;
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding: 28px 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .item-l {
        font-size: 32px;
        color: #333333;
        line-height: 44px;
        position: relative;
        &::before {
          content: "*";
          position: absolute;
          color: #e02020;
          left: -16px;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .item-r {
        flex: 1;
        position: relative;
        :deep .van-cell {
          padding: 0;
          input {
            text-align: right;
          }
        }
        span {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 50px;
        }
      }
    }
    .te {
      .item-r {
        padding-right: 50px;
      }
    }
    .textItem {
      display: block;

      .item-r {
        padding-top: 20px;
        :deep .van-field__control {
          background: #f8f8f8;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 8px;
          height: 270px;
        }
      }
    }
    .imgItem {
      border: none;
      .item-l {
        &::before {
          content: "";
        }
      }
    }
  }
  .btn {
    width: 550px;
    height: 70px;
    background: #3781ff;
    border-radius: 16px;
    font-weight: 600;
    font-size: 30px;
    color: #ffffff;
    line-height: 70px;
    text-align: center;
    color: #fff;
    margin: 124px auto 0;
  }
  .save {
    width: 550px;
    height: 70px;
    background: #3781ff;
    border-radius: 16px;
    font-weight: 600;
    font-size: 30px;
    color: #ffffff;
    line-height: 70px;
    text-align: center;
    color: #fff;
    margin: 124px auto 0;
  }
  .del {
    width: 550px;
    height: 70px;
    background: #db431d;
    border-radius: 16px;
    font-weight: 600;
    font-size: 30px;
    color: #ffffff;
    line-height: 70px;
    text-align: center;
    color: #fff;
    margin: 24px auto 0;
  }
  .modify {
    width: 550px;
    height: 70px;
    background: #f2ba22;
    border-radius: 16px;
    font-weight: 600;
    font-size: 30px;
    color: #ffffff;
    line-height: 70px;
    text-align: center;
    color: #fff;
    margin: 124px auto 0;
  }
}
</style>
